<template>
  <v-container class="pa-10" fluid>
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="contract"
                :loading="loading"
                loading-text="Please wait..."
                no-data-text="No Specification available"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <!-- <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2"
                      >Product Specification
                    </v-toolbar-title> -->
                    <v-row>
                      <p
                        class="mt-5 mb-2 ml-1 font-weight-bold d-inline-block text-truncate"
                        style="max-width: 500px"
                      >
                        Product Title : {{ product_title }}
                      </p>
                      <!-- <p style="margin-left: 224px" class="mt-5 mb-2">
                        Product code : {{ product_id }}
                      </p> -->
                      <v-spacer></v-spacer>

                    
                    </v-row>
                  </v-toolbar>
                </template>
          

              </v-data-table>
            </v-card>
          </v-col>

      
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>



<script>
export default {
  data: () => ({


    
    loading: true,
    

    headers: [
      { text: "SKU", value: "SKU", sortable: false },
      // { text: "Barcode", value: "barcode", sortable: false },
      { text: "Color", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      { text: "Weight", value: "weight", sortable: false },
      { text: "Variant", value: "weight_unit", sortable: false },
      { text: "Quantity", value: "quantity", sortable: false },
      { text: "Purchase Price", value: "purchase_price", sortable: false },
      { text: "Selling Price", value: "new_price", sortable: false },
 
     
    ],

    contract: [],
    specValue: [],
    contact: {},
   
    temp: {},





    text: "",
    color:"",
    snackbar: false,

    user: {},



  }),


  methods: {
    // Add specification modal
    addnew() {
      this.addDialog = true;
    },

    // Get all specifications
    initialize() {
      axios
        .get(`productdetails/showspec/${this.$route.query.id}/`)
        .then((response) => {
          if (Array.isArray(response.data.data)) {
            this.contract = response.data.data;
            // console.log("specification value", this.contract);
          }
          // if(response.data.success){
          //   this.specValue=response.data.data

          // }
          this.product_id = this.$route.query.id;
          this.unit = this.$route.unit;
          this.product_title = response.data.product_title;
          this.loading = false;
        });
    },


   

  


  },

  mounted() {
    this.initialize();
    this.user = JSON.parse(localStorage.getItem("user"));

    // Fetch all available Districts
    axios.get(`supports/all_areas/`).then((response) => {
      if (response.data.success) {
        this.districts = response.data.data;
        // keep refernce
        this.districtsArrayForCalculation = response.data.data;

        //  this.districts = [
        //    {
        //      Area_name : "Dhaka"
        //    },
        //    {
        //      Area_name : "B"
        //    },
        //    {
        //      Area_name : "C"
        //    }
        //  ];
        // // keep refernce
        // this.districtsArrayForCalculation = this.districts
      }
    });
  },
};
</script>
<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>
